.relationInformationTab {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.relationDetailsContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.relationDetailsContainer > div {
  flex: 3;
}

.relationDetailsContainer > div:last-child {
  flex: 4;
}

.relationDetailsContainer > div:first-child {
  margin-right: 80px;
}

.formName {
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.entityCard {
  background-color: var(--secondaryBackground);
}

.verticalInfoPanelContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.verticalTextArrowContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.infoPanelContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.infoPanelContainer > div {
  flex: 2;
}

.infoPanelContainer > div:nth-child(2) {
  flex: 1;
}

.entityCardTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.entityCardTitle svg {
  margin-right: 10px;
}

.entityCardTitleArrow {
  margin-left: auto;
}

.entityCardProperty {
  margin-bottom: 10px;
}

.entityCardProperty > div:last-child {
  font-size: 0.875rem;
  color: var(--primaryTextColor);
}

.ownershipArrowContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  font-size: 0.875rem;
  color: var(--primaryTextColor);
}

.ownershipArrowContainer > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.confirmationMessageContainer {
  margin-top: 20px;
}
