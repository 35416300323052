.oetRelationCreationPopupContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin: 20px 0;
}

.oetRelationFormFields{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;
}

.relationDirectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 30%;
}

.oetRelationTextArrowContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.relationDetailsContainer :global(.MuiCard-root) {
  background-color: var(--secondaryBackground);
  padding: 10px;
  max-width: 400px;
}
