/* source-sans-pro-300 - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  src: local("./fonts/source-sans-pro-v14-latin-300.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
  local("./fonts/source-sans-pro-v14-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* source-sans-pro-regular - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: local("./fonts/source-sans-pro-v14-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
  local("./fonts/source-sans-pro-v14-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* Manrope font */
@font-face {
  font-family: 'Manrope';
  src: local('Manrope Regular') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: local('Manrope Bold') format('truetype');
  font-weight: 800;
  font-style: normal;
}

/* default theme */
:root {
  --primaryColor: #0047BB;
  --primaryColorRGBA: 0, 71, 187;
  --secondaryColor: #7f56c5;
  --errorColor: #DA1E28;
  --primaryTextColor: #1d1f29;
  --secondaryTextColor: #ffffff;
  --primaryBackground: #ffffff;
  --topnavBackground: #1d1f29;
  --sidenavBackground: #ffffff;
  --tabbarColor: #F4F7F8;
  --primaryIconColor: #F4F7F8;
  --secondaryIconColor: #1D1F29;
  --shadowColor: #DDE1E9;
  --fontFamily: Source Sans Pro, sans-serif;
  --buttonWidth: 120px;
  --fontWeightLight: 300;
  --secondLevelTabBackground: rgb(243, 243, 243);
  --borderColor: #C6C6C6;
  --headerBorderColor: var(--primaryColor);
  --backgroundHoverColor: #0043ce1a;
  --alternativeTableCellColor: #f4f7f8;
}

:root[data-theme="dark"] {
  --primaryBackground: #121212;
  --navbarBackground: #678c7c;
  --primaryColor: #2f896b;
  --primaryIconColor: #f2e9e9;
  --shadowColor: #2f896b;
}

/*
  override default theme
  To apply this theme, set attribute "theme" of root element to "fusion"
*/
:root[theme="fusion"] {
  --primaryColor: #2690B5;
  --secondaryTextColor: #999999;
  --secondaryBackground: #e6e6e6;
  --topnavBackground: #fff;
  --shadowColor: #fff;
  --headerBorderColor: var(--borderColor);
}

body {
  font-family: var(--fontFamily);
  font-size: 100%;
  background: var(--primaryBackground);
  color: var(--primaryTextColor) !important;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
}
