.listContainer {
  margin-top: 30px;
}

.rowGroupThreeColumns {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: auto;
  padding-left: 20px;
  width: 100%;
}

.rowGroupRuleInformation {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: auto;
  padding-left: 20px;
  padding-top: 20px;
}

.deleteCriteriaGroupButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

.ruleMargin {
  margin-left: 20px;
  margin-bottom: 20px;
  position: relative;
}

.ruleOperator {
  margin-left: 20px;
  margin-bottom: 20px;
  color: var(--primaryTextColor);
}

.criteriaGroupTitle {
  font: inherit;
  font-weight: bold;
  color: var(--primary-color);
  margin: 20px;
}

