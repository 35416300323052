@use "../../../../../shared/theme/src/lib/sivisTheme";

/* mobile styles */
.toggleBtn {
  width: 40px;
  height: sivisTheme.$sivisTopNavbarHeight;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: 17.5px 0;
  box-sizing: border-box;
  cursor: pointer;
  float: left;
}

.toggleBtn span {
  display: block;
  width: 90%;
  height: 1px;
  background-color: var(--secondaryTextColor);
  transition: 0.4s;
}

/* Rotate first bar */
.toggleBtn .bar1 {
  //-webkit-transform: rotate(-45deg) translate(0px, 9px);
  transform: rotate(-45deg) translate(-8px, 9px);
  width: 100%;
}

/* Fade out the second bar */
.toggleBtn .bar2 {
  opacity: 0;
}

/* Rotate last bar */
.toggleBtn .bar3 {
  //-webkit-transform: rotate(45deg) translate(0px, -9px);
  transform: rotate(45deg) translate(-8px, -9px);
  width: 100%;
}

/* Rotate first bar */
.toggleBtn.closed .bar1 {
  //-webkit-transform: rotate(0deg) translate(0px, 0px);
  transform: rotate(0deg) translate(0px, 0px);
  width: 90%;
}

/* Fade in the second bar */
.toggleBtn.closed .bar2 {
  opacity: 1;
}

/* Rotate last bar */
.toggleBtn.closed .bar3 {
  //-webkit-transform: rotate(0deg) translate(0px, 0px);
  transform: rotate(0deg) translate(0px, 0px);
  width: 90%;
}
