.errorContainer {
  display: flex;
  flex-direction: column;
  max-height: 100px;
  overflow: auto;
}

.error {
  color: red;
  white-space: pre-wrap;
  word-break: break-word;
}
