.rowGroup {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--secondaryBackground);
  border-radius: 5px;
  padding: 20px;
  height: 500px;
  overflow-y: auto;
}

.entityFooter {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.entityFooter button:not(:last-child) {
  margin-right: 10px;
}

.row {
  display: flex;
  flex-direction: column;
  height: auto;
  /* Adjust height to auto */
  margin-bottom: 20px;
  /* Add space between rows */
  align-items: flex-start;
}

.row>div:first-child {
  margin-bottom: 5px;
}

.row>div:nth-child(2) {
  display: flex;
  flex-direction: column;
  /* Change to column for better spacing */
  width: 100%;
}

.row>div:nth-child(2)>div {
  flex: 1;
}

.errorRow {
  color: red;
}

.errorRow>div:not(:first-child) {
  margin-bottom: 10px;
}

.rowName {
  font-weight: bold;
  margin-bottom: 5px;
}

.twoColumnsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  /* Add space between columns */
}

.twoColumnsContainer>div {
  width: 48%;
}

.threeColumnsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.threeColumnsContainer > div {
  width: 25%;
}

.widerRows .row {
  height: auto;
  /* Adjust height to auto */
}

.widerRows .row>div:first-child {
  width: 500px;
}

.vertical-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.vertical-layout > * {
  margin-bottom: 15px;
}

.vertical-layout > *:last-child {
  margin-bottom: 0;
}
