@use "../../../../theme/src/lib/sivisTheme";

.muiDialogLoginContainer > div:nth-child(3) > div {
  height: 100%;
  margin: 10px;
}

/* small desktop styles */
@media (min-width: sivisTheme.$sivisMobileScreenSize) {
  .muiDialogLoginContainer > div:nth-child(3) > div {
    height: unset;
    margin: unset;
  }
}
