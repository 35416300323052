.clickableText {
  font-size: 0.8rem;
  color: var(--primaryTextColor);
  cursor: pointer;
  text-decoration: underline;
  border: none;
  background-color: unset;
  padding: unset;
  font-family: inherit;
}

.clickableText:hover {
  color: var(--secondaryColor);
}
