$blurSize: 8px;

/* mobile styles */
.loginSite {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primaryBackground);
  margin: auto;
}

.containerDiv {
  display: flex;
  flex-wrap: nowrap;
  width: 80vw;
  height: 90vh;
  box-shadow: 0px 0px 40px $blurSize var(--shadowColor);
  border-radius: 20px 20px 20px 20px;
}

.leftSide {
  width: 55%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px 0 0 20px;
  background-color: var(--primaryBackground);
  box-shadow: 0px 0px 40px 2px var(--shadowColor);
}

.rightSide {
  flex-grow: 1;
  border-radius: 0 20px 20px 0;
}

.backgroundRightSide {
  margin-top: -$blurSize;
  margin-left: -$blurSize;
  background-image: url("./assets/login_background.jpg");
  background-repeat: no-repeat;
  background-color: var(--topnavBackground);
  background-position: center;
  background-size: cover;
  filter: blur($blurSize);
  -webkit-filter: blur($blurSize);
  opacity: 0.2;
  width: calc(100% + (2 * #{$blurSize}));
  height: calc(100% + (2 * #{$blurSize}));
  border-radius: 0 20px 20px 0;
}

$sidePadding: 30px;

.hasMaxWidth {
  max-width: 360px;
}

.loginTable > div {
  display: flex;
  flex-direction: row;
  margin-left: 3px;
  margin-bottom: 3px;
}

.loginTable > div > div:nth-child(1) {
  width: 60px;
}

.loginTable > div > div:nth-child(2) {
  padding-left: 10px;
  line-break: anywhere;
}

.loginTable {
  @extend .hasMaxWidth;
  margin-top: 8px;
}

.loginContainer {
  @extend .hasMaxWidth;
  padding: 30px $sidePadding 0px $sidePadding;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.loginContainer :global(.MuiTab-root) {
  font-size: 1rem;
}

.loginHeader {
  border-bottom: 2px solid var(--primaryColor);
  padding-bottom: 2px;
  width: fit-content;
}

.loginHeaderWithTabs {
  @extend .loginHeader;
  border-bottom: none;
}

.loginText {
  @extend .hasMaxWidth;
  font-size: 0.9rem;
  margin-bottom: 30px;
}

.loginTextWithTabs {
  @extend .loginText;
  margin-top: 20px;
}

.loginTextDense {
  @extend .loginText;
  margin-top: 0;
  margin-bottom: 20px;
}

.loginForm form {
  @extend .hasMaxWidth;
  display: block;
  text-align: left;
}

.logo {
  padding-top: 30px;
  padding-left: 40px;
  width: 110px !important;
  position: absolute;
  z-index: 1;
}

.mobileLogoContainer {
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileLogoContainer > img {
  margin: auto;
  width: 150px !important;
}

.maintenanceWarning {
  color: red;
}

.samlLoginForm {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.samlLoginAlternativeText {
  font-size: 0.9rem;
}

// small heights --> hide logo (no space for it)
@media (max-height: 620px) {
  .loginContainer {
    padding: 5px 5px 0px 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 360px;
    max-width: calc(100vw - (2 * #{$sidePadding}));
    margin: auto;
  }

  .mobileLogoContainer {
    padding-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobileLogoContainer > img {
    margin: auto;
    width: 0px !important;
    display: none !important;
  }
}

/* Full-width inputs */
.inputField[type="text"],
.inputField[type="password"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}


$leftMarginRightSideElements: 30px;

.loginButtonContainer {
  margin: $leftMarginRightSideElements 0px 30px 0px;
  display: flex;
  justify-content: center;
}

.loginButtonContainer button:not(:first-child) {
  margin-left: 10px;
}

.loginButtonContainerRightSide {
  @extend .loginButtonContainer;
  justify-content: flex-end;
}

.websiteButtonContainer {
  margin: 10px 0 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textRightSide {
  position: absolute;
  margin-top: 300px;
  margin-left: $leftMarginRightSideElements;
  font-size: 2rem;
  width: 250px;
  z-index: 1;
}
