@use '../../../../../shared/theme/src/lib/sivisTheme';

.newDesign {
  &#evoSideNavbar {
    width: sivisTheme.$pointsharpSidebarWidth;
    box-shadow: none;
    display: flex;
    flex-direction: column;
  }
  &#evoSideNavbar.closed {
    box-shadow: none;
  }
}

#evoSideNavbar {
  display: initial;
  min-height: 400px;
  transition: 0.6s;
}

/* small desktop styles */
@media (min-width: sivisTheme.$sivisMobileScreenSize) {
  #evoSideNavbar {
    width: sivisTheme.$sivisClosedSidebarWidth;
    display: initial;
    min-height: 400px;
    transition: 0.6s;
  }

  #evoSideNavbar.closed {
    width: sivisTheme.$sivisClosedSidebarWidth;
    z-index: sivisTheme.$sivisZindexSideNavBarShadow;
    display: flex;
    flex-direction: column;
    box-shadow: 5px 0px 7px var(--shadowColor);
  }
}

/* desktop styles */
@media (min-width: sivisTheme.$sivisLargeDesktopScreenSize) {
  #evoSideNavbar {
    width: sivisTheme.$sivisOpenSidebarWidth;
    display: flex;
    flex-direction: column;
    box-shadow: 5px 0px 7px var(--shadowColor);
    z-index: sivisTheme.$sivisZindexSideNavBarShadow;
  }
}
