
.forwardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0px 5px 15px var(--shadowColor);
  padding: 10px;
}

.forwardContainer > img {
  margin: auto;
  width: 150px !important;
}
