$icon-color: #b9b9b9;

.entityTable {
  height: 450px;
}

.infiniteScrollTable {
  @extend .entityTable;
  height: 500px;
}

.entityTable :global(.MuiDataGrid-pinnedRows--top) {
  // The overlay shown when there are no rows has z-index 4
  // pinned rows must be shown above the overlay to allow user interaction
  z-index: 5;
}

.entityTable :global(.MuiDataGrid-columnHeaderTitle) {
  font-weight: bold;
}

.entityTable :global(.MuiDataGrid-root) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.entityTable a {
  text-decoration: none;
  color: var(--primaryColor);
}

.entityTable svg {
  color: $icon-color;
}

.entityTable :global(.MuiCheckbox-root) svg {
  color: unset;
}

.toolbar {
  display: flex;
  align-items: center;
}

.toolbar > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toolbarEntryCount {
  font-size: 0.875rem;
  margin-left: 10px;
}

.searchWrapper {
  display: flex;
  align-items: center;
  width: 33.33%;
}

.buttons {
  margin-left: auto;
}

.filterContainer {
  border-right: 0;
  width: 200px;
  display: flex;
  flex-direction: column;
  padding-top: 5px
}

.filterTitle {
  padding: 15px 10px 15px 10px;
  border-left: 0;
  border-right: 0;
  border-top: 0;
}

.filterContentContainer {
  flex: 1;
  overflow-y: auto;
}

.filterGroupContainer {
  @extend .filterContainer;
  border-left: 0;
  border-top: 0;
}

.filterGroupTitle {
  padding: 10px;
  font-weight: 590;
}

.filterText {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.9rem;
}
