.listContainer {
  margin-top: 30px;
}

.deleteButtonContainer {
  display: flex;
  justify-content: flex-end;
}

.container {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
  background-color: #f9fafb;
}

/* Card Styling */
.bg-white {
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem;
  padding: 24px;
}

/* Header and Text Styling */
.mb-4 {
  margin-bottom: 16px;
}

.border-b {
  border-bottom: 1px solid #e2e8f0;
}

.pb-4 {
  padding-bottom: 16px;
}

.text-xl {
  font-size: 1.25rem;
  font-weight: 600;
}

.font-semibold {
  font-weight: 600;
}

.text-gray-700 {
  color: #4a5568;
}

.text-gray-500 {
  color: #6b7280;
}

.text-sm {
  font-size: 0.875rem;
  /* 14px */
}

/* Grid Layout for Fields */
.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

/* Draggable Item Styling */
.draggable-item {
  background-color: #f7fafc;
  padding: 16px;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  cursor: grab;
}

.draggable-item:active {
  cursor: grabbing;
}

