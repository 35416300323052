.title {
  display: flex;
  flex-direction: column;
  min-width: 600px;
}

.title > div:first-child {
  color: black;
  line-height: 90%;
}

.title > div:last-child {
  font-size: 0.9rem;
}

.closeIcon {
  position: absolute !important;
  right: 4px;
  top: 4px;
}

.popupContent {
  display: flex;
  flex-direction: column;
}

.messagePopup :global(.MuiDialog-paper) {
  width: 750px;
  height: 200px;
}

.editPopup :global(.MuiDialog-paper) {
  width: 1000px;
  height: 1000px;
}

.confirmPopup :global(.MuiDialog-paper) {
  width: 750px;
  height: auto;
}
