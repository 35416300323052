.loadingButtonContainer {
  position: relative;
  display: flex;
  justify-content: center;
}

.loadingButtonContainer :global(.MuiButton-outlined.Mui-disabled) {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.loadingButtonContainer:not(:last-of-type) button {
  border-right-color: transparent !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.loadingButtonContainer button {
  border-radius: 4px !important;
  margin-bottom: 0;
}

.loadingButtonContainer:not(:first-child) button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: -1px;
}

.buttonProgress {
  position: absolute;
  top: 6px;
}
