.listContainer {
  margin-top: 20px;
  color: var(--primaryTextColor);
}

.tabContainer {
  @extend .listContainer;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.informationCardContainer {
  margin-bottom: 20px;
}
