.relationDetailsContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.relationDetailsContainer > div {
  flex: 3;
}

.relationDetailsContainer > div:last-child {
  flex: 2;
}

.relationDetailsContainer > div:first-child {
  margin-right: 80px;
}

.infoPanelContainer {
  margin-top: 10px;
}

.infoPanelContainer > div {
  flex: 2;
}

.infoPanelContainer > div:nth-child(2) {
  flex: 1;
}
