.sidebar {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid var(--borderColor);
}

.sidebarItemContainer {
  text-decoration: none;
  color: var(--primaryTextColor);
  cursor: pointer;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.category {
  @extend .sidebarItemContainer;
  padding-left: 30px;
  font-weight: bold;
}

.hide {
  display: none;
}

.sidebar a {
  @extend .sidebarItemContainer;
  padding-left: 50px;
}

.sidebar a:hover {
  background-color: var(--backgroundHoverColor);
}

.selected {
  background-color: var(--backgroundHoverColor);
}
