@use "../../../../../shared/theme/src/lib/sivisTheme";

:global(.appShell-breadcrumbBar) {
  display: none;
}

@media (min-width: sivisTheme.$sivisMobileScreenSize) {
  :global(.appShell-breadcrumbBar) {
    height: sivisTheme.$sivisBreadcrumbsHeight;
    z-index: sivisTheme.$sivisZindexBreadSearchBar;
    display: flex;
    width: 100%;
  }
}
