$font-size-xs: 0.75rem;
$font-size-sm: 0.875rem;
$font-weight-medium: 500;
$color-gray-700: #4a5568;
$color-gray-200: #edf2f7;
$color-gray-500: #6b7280;
$padding-1: 0.25rem;
$rounded-value: 0.375rem;
$color-blue: #2196f3;
$color-pink: #ff4081;

.textXs {
  font-size: $font-size-xs;
  cursor: pointer;
}

.fontMedium {
  font-weight: $font-weight-medium;
}

.textGray700 {
  color: $color-gray-700;
}

.bgGray200 {
  background-color: $color-gray-200;
}

.rounded {
  border-radius: $rounded-value;
}

.container {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 24px;
  background-color: #ffffff;

  @media (min-width: 1024px) {
    max-width: 1200px;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(250px, 1fr));
  width: 100%;
  gap: 16px;
}

// Draggable Item Styling
.draggableItem {
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  min-height: 40px;
  width: 90%;
  background-color: $color-gray-200;
  margin-bottom: 20px;
  border-radius: $rounded-value;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
  0 1px 2px 0 rgba(0, 0, 0, 0.06);
  cursor: grab;
  border: 2px solid transparent;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
  }

  &:active {
    cursor: grabbing;
  }

  // Sortable drag ghost styling
  &.sortable-ghost {
    opacity: 0.4;
    background-color: $color-gray-200 !important;
    border: 2px dashed $color-gray-500 !important;
  }

  &.sortable-drag {
    background-color: white !important;
    border: 2px solid $color-gray-500 !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }
}


// Only show these styles when not mouse dragging
.selectedItem {
  border-color: $color-blue !important;
  background-color: rgba($color-blue, 0.08) !important;
  box-shadow: 0 0 0 1px rgba($color-blue, 0.2);
}

.grabbedItem {
  border: 2px dashed $color-blue !important;
  background-color: rgba($color-blue, 0.08) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.draggableItemContent {
  flex: 1;
}

.draggableItemHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.dragHandle {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  cursor: move;
  width: 32px;
}

.draggableItemText {
  flex: 1;
  padding-left: 8px;
}

.draggableItemButtons {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-left: auto;
  margin-right: 50px;
}

.moreButton {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  opacity: 0.7;
  font-size: 12px;
}

.closeButton {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  font-size: 14px;
  color: #666;
  font-weight: bold;
  opacity: 0.7;
}

.draggableItemInputContainer {
  width: 90%;
  padding-right: 24px;
}

.draggableItemHeader,
.draggableItemInputContainer {
  padding-left: 48px;
}

.rowGroupCompact {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--secondaryBackground);
  border-radius: 5px;
  padding: 10px;
  height: 450px;
  overflow-y: auto;
  gap: 8px;
  margin-bottom: 1rem;
}

.popupContent {
  padding: 20px;
}

.layoutWrapper {
  display: flex;
  gap: 24px;
  width: 100%;
  position: relative;
}

.mainContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.infoBoxContainer {
  flex: 1;
  display: flex;
  justify-content: center;
}

.infoBox {
  width: auto;
  background-color: $color-gray-200;
  border: 1px solid #e9ecef;
  border-radius: $rounded-value;
  padding: 16px;
  margin-left: 10px;

  // Typography
  font-size: $font-size-sm;
  line-height: 1.5;
  color: $color-gray-700;

  // Box shadow
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.nonInteractive {
  cursor: default; // No pointer
  pointer-events: none; // Prevent any interaction
  background-color: #f0f0f0; // Optional: Suggest it's disabled visually
}
