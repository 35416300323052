@use '../../../shared/theme/src/lib/sivisTheme';

/* mobile styles */
#evoraptorMain {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#evoContent {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
}

#evoContentRightSide {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

#evoPageContent {
  height: 100%;
  overflow-x: hidden;
  overflow-y: overlay;
  display: flex;
  flex-direction: column;
}

:global(.sivisPaddingContainer) {
  flex: 1;
}

// Chrome: remove outline for mouse user and set for keyboard user
.selector:not(*:root),
body:not(.user-is-tabbing) :focus {
  outline: none !important;
}

.selector:not(*:root),
body[class='user-is-tabbing'] :not([class*='MuiInput']):focus {
  outline: var(--primaryColor) auto 5px !important;
}

// Firefox: remove outline for mouse user
body:not(.user-is-tabbing) ::-moz-focus-inner {
  border: 0;
}

.muiDialogLoginContainer > div:nth-child(3) > div {
  height: 100%;
  margin: 10px;
}

/* small desktop styles */
@media (min-width: sivisTheme.$sivisMobileScreenSize) {
  .muiDialogLoginContainer > div:nth-child(3) > div {
    height: unset;
    margin: unset;
  }

  #evoPageContent {
    flex: 1;
  }

  // The desktop padding previously set in #evoPageContent has been removed
  // because not all components need it. Use this global class in existing components
  // where padding is needed as a quick fix.
  :global(.sivisPaddingContainer) {
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
  }
}
