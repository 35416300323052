$pointsharpTopNavbarHeight: 64px;
$pointsharpSidebarWidth: 240px;

$sivisTopNavbarHeight: 60px;
$sivisOpenSidebarWidth: 250px;
$sivisClosedSidebarWidth: 60px;
$sivisBreadcrumbsHeight: 35px;
$sivisSidenavIconWidth: 40px;

$pointsharpTopNavbarHeight: 56px;
$pointsharpSidebarWidth: 240px;

$sivisLargeDesktopScreenSize: 1250px; // Include sidebar into calculation
$sivisMobileScreenSize: 1000px;

// high z-index due to MUI (https://mui.com/material-ui/customization/z-index/)
$sivisZindexBreadSearchBar: 1;
$sivisZindexSideNavBarShadow: 1;
$sivisZindexBackDrop: 1101;
$sivisZindexSideNavBar: 1102;
// show language & message popup above mui app bar (1100)
$sivisZindexNavBarPopup: 1101;
$sivisZindexLogout: 10004;
$sivisZindexProjectSwitcher: 10005;
$sivisZindexSpinner: 10099;
$sivisZindexToast: 10100;

$sivisSmallFontSize: 0.9rem;
