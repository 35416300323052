.breadcrumbContainer,
.breadcrumb {
  display: flex;
}

.breadcrumb {
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}

.breadcrumb span {
  display: flex;
  align-items: center;
}

.breadcrumbContainer {
  display: flex;
  align-items: center;
}

.breadcrumb a {
  padding: 0 10px;
  font-size: 16px;
  text-decoration: none;
  color: var(--primaryTextColor);
}
