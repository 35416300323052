@use "./../../../../../shared/theme/src/lib/sivisTheme";

.linkText {
  font-size: 1.1rem;
  margin-left: 5px;
  color: var(--primaryTextColor);
}

/* desktop styles */
@media (min-width: sivisTheme.$sivisMobileScreenSize) {
  .linkText {
    display: none;
  }
}
