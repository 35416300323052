.container {
  margin: 0 60px 10px 40px;
}

.container h1 {
  margin-top: 10px;
}

.tabBarContainer {
  margin-top: 10px;
}

.childrenContainer {
  margin-top: 30px;
}

.actionButtonContainer button {
  margin-right: 10px;
}

.addItemPanel {
  display: flex;
  justify-content: space-between;
}
