.statusBar {
    margin-bottom: 1rem;
    border-radius: 8px;
    padding: 1.5rem;
    border-color: var(--borderColor);
    background-color: #fbfbfb;
    box-shadow: inset 0 0 0 2px var(--borderColor);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.statusElement {
    margin-right: 1rem;
    display: flex;
    align-items: center;
}

.statusText {
    font-weight: bold;
    margin-right: 0.5rem;
}

.statusIcon {
    margin-left: 0.5rem;
}

.draftCard {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  border-color: var(--borderColor);
  background-color: #fbfbfb;
  box-shadow: inset 0 0 0 2px var(--borderColor);
}

.draftCardContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
