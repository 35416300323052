@use "./../../../../../shared/theme/src/lib/sivisTheme";

/* mobile styles first */
#headerItems {
  flex: 1;
  height: 100%;
  font-weight: var(--fontWeightLight);
  padding: 0 10px;
  box-sizing: border-box;
  color: var(--secondaryTextColor);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.leftSide, .middle, .rightSide {
  display: flex;
  flex-direction: row;
}

.middle {
  flex: 1;
  position: relative;
}

.headerItemsContainer {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.headerTitle {
  position: absolute;
  left: 55%;
  font-size: 2em;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  white-space: nowrap;
  max-width: 80%;
  overflow: hidden;
}

@media (max-width: sivisTheme.$sivisMobileScreenSize) {
  #headerItems {
    display: none;
  }

  .headerTitle {
    display: none;
  }
}
