@use '../../../../../shared/theme/src/lib/sivisTheme';

// Apply only if it's a new design. This rule is based on the newDesign prop in AppShell
.newDesign {
  &.header {
    height: sivisTheme.$pointsharpTopNavbarHeight;
    padding: 0 16px;
    border-bottom: 0;
  }
  & .company {
    height: calc(#{sivisTheme.$pointsharpTopNavbarHeight} - 2px);
    margin-left: 0;
    width: 127px;
  }
}

.header {
  height: sivisTheme.$sivisTopNavbarHeight;
  font-weight: var(--fontWeightLight);
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  color: var(--secondaryTextColor);
  background-color: var(--topnavBackground);
  border-bottom: 2px solid var(--headerBorderColor);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.company {
  font-weight: bold;
  font-size: 3em;
  width: 100px;
  height: calc(
    #{sivisTheme.$sivisTopNavbarHeight} - 2px
  ); // prevent from overlapping bottom border
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sivislogo {
  max-height: 100%;
  max-width: 100%;
}

.backArrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 30px;
  height: 100%;
}

.innerArrow {
  border: solid var(--secondaryTextColor);
  border-width: 0 0.3em 0.3em 0;
  padding: 0.5em;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

/* mobile only */
@media (max-width: sivisTheme.$sivisMobileScreenSize) {
  .company {
    flex: 1;
  }
}

/* desktop styles */
@media (min-width: sivisTheme.$sivisMobileScreenSize) {
  .company {
    margin-left: 20px;
  }

  .backArrow {
    display: none;
  }
}
