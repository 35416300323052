.breadcrumb {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}

.breadcrumb span {
  display: flex;
  align-items: center;
}

.breadcrumbContainer {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.breadcrumb a {
  padding: 0 10px;
  font-size: 16px;
  text-decoration: none;
  color: var(--primaryTextColor);
}

.breadcrumbLast a {
  color: black;
}

:global(.appShell-breadcrumbBar) {
  height: 50px !important;
}