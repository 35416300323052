.appNameBlack {
  font-size: 1.5rem;
  color: var(--primaryTextColor);
}

.appNameWhite {
  font-size: 1.5rem;
  color: var(--secondaryTextColor);
}

.searchFieldContainer {
  margin: 20px;
  margin-left: 60px;
}
