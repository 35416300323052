.dividerMargin {
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.directionBox {
    display: block;
    width: 100%;
    
}

.box {
    display: block;
    width: 100%;
}

.textField {
    width: 50%;
}

.cardField {
    width: 50%;
    background-color: white;
}

.card {
    background-color: #fcfcfc;
    border: 2px solid #d5e6f7;
    border-radius: 0.5em;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.header {
    margin-bottom: 2em;
}

.inputWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.buttonWrapper {
    display: flex;
    justify-content: flex-end;
}

.transformationHeaderWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1em;
}

.transformationFieldWrapper {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    margin-top: 1em;
}

.fieldNumber {
    margin-right: 1em;
}

.deleteButton {
    margin-left: auto;
}